// Cart add remove functions
var cart = {
	'timeoutID': false,
	'timer': 1200,
	'add': function(product_id, quantity) {
		$.ajax({
			url: 'index.php?route=checkout/cart/add',
			type: 'post',
			data: 'product_id=' + product_id + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
			dataType: 'json',
			beforeSend: function() {
				preloader.start();
				// $('#cart button').prop('disabled', true);
			},
			complete: function() {
				preloader.stop();
				// $('#cart button').prop('disabled', true);
			},
			success: function(json) {
				$('.alert-dismissible, .text-danger').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
					// $('#content').parent().before('<div class="alert alert-success alert-dismissible"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');

					// $('html, body').animate({ scrollTop: 0 }, 'slow');

					// $('#cart > ul').load('index.php?route=common/cart/info ul li');

					if(json['count']){
						$('header .container .cart .number').html(json['count']);
						$('header').addClass('show');
					}
					// setTimeout(function () {
					// 	$('header').removeClass('show');
					// },1000);
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				// alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'update': function(key, quantity) {
		if(this.timeoutID !== false){
			clearTimeout(this.timeoutID);
		}
		this.timeoutID = setTimeout(function () {
			$.ajax({
				url: 'index.php?route=checkout/cart/edit',
				type: 'post',
				data: 'key=' + key + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
				dataType: 'json',
				beforeSend: function() {
					preloader.start();
					$('#cart button').prop('disabled', true);
				},
				complete: function() {
					$('#cart button').prop('disabled', false);
				},
				success: function(json) {
					// Need to set timeout otherwise it wont update the total
					// setTimeout(function () {
					// 	$('#cart button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
					// }, 100);

					if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
						location = 'index.php?route=checkout/cart';
					} else {
						$('header .container .cart .number').html(json['count']);
						preloader.stop();
					}
				},
				error: function(xhr, ajaxOptions, thrownError) {
					// alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
				}
			});
		},this.timer);

	},
	'updateTare': function(quantity) {
		if(this.timeoutID !== false){
			clearTimeout(this.timeoutID);
		}
		this.timeoutID = setTimeout(function () {
			$.ajax({
				url: 'index.php?route=event/cart/addTare',
				type: 'post',
				data: 'tareBottle=' + (typeof(quantity) != 'undefined' ? quantity : 0),
				dataType: 'html',
				beforeSend: function() {
					preloader.start();
					$('#cart button').prop('disabled', true);
				},
				complete: function() {
					$('#cart button').prop('disabled', false);
				},
				success: function(json) {
					// Need to set timeout otherwise it wont update the total
					// setTimeout(function () {
					// 	$('#cart button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
					// }, 100);

					if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
						location = 'index.php?route=checkout/cart';
					} else {
						$('header .container .cart .number').html(json['count']);
						preloader.stop();
					}
				},
				error: function(xhr, ajaxOptions, thrownError) {
					// alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
				}
			});
		},this.timer);
	},
	'remove': function(key) {
		if(this.timeoutID !== false){
			clearTimeout(this.timeoutID);
		}
		this.timeoutID = setTimeout(function () {
			$.ajax({
				url: 'index.php?route=checkout/cart/remove',
				type: 'post',
				data: 'key=' + key,
				dataType: 'json',
				beforeSend: function() {
					preloader.start();
					$('#cart button').prop('disabled', true);
				},
				complete: function() {
					$('#cart button').prop('disabled', false);
				},
				success: function(json) {

					if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
						location = 'index.php?route=checkout/cart';
					} else {
						preloader.stop();
						$('header .container .cart .number').html(json['count']);
					}
				},
				error: function(xhr, ajaxOptions, thrownError) {
					// alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
				}
			});
		},this.timer);
	}
}

var voucher = {
	'add': function() {

	},
	'remove': function(key) {
		$.ajax({
			url: 'index.php?route=checkout/cart/remove',
			type: 'post',
			data: 'key=' + key,
			dataType: 'json',
			beforeSend: function() {
				$('#cart button').prop('disabled', true);
			},
			complete: function() {
				$('#cart button').prop('disabled', false);
			},
			success: function(json) {
				// Need to set timeout otherwise it wont update the total
				setTimeout(function () {
					$('#cart button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
				}, 100);

				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
					location = 'index.php?route=checkout/cart';
				} else {
					$('#cart > ul').load('index.php?route=common/cart/info ul li');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				// alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	}
}

var wishlist = {
	'add': function(product_id) {
		$.ajax({
			url: 'index.php?route=account/wishlist/add',
			type: 'post',
			data: 'product_id=' + product_id,
			dataType: 'json',
			success: function(json) {
				$('.alert-dismissible').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
					$('#content').parent().before('<div class="alert alert-success alert-dismissible"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');
				}

				$('#wishlist-total span').html(json['total']);
				$('#wishlist-total').attr('title', json['total']);

				$('html, body').animate({ scrollTop: 0 }, 'slow');
			},
			error: function(xhr, ajaxOptions, thrownError) {
				// alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function() {

	}
}

var compare = {
	'add': function(product_id) {
		$.ajax({
			url: 'index.php?route=product/compare/add',
			type: 'post',
			data: 'product_id=' + product_id,
			dataType: 'json',
			success: function(json) {
				$('.alert-dismissible').remove();

				if (json['success']) {
					$('#content').parent().before('<div class="alert alert-success alert-dismissible"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');

					$('#compare-total').html(json['total']);

					$('html, body').animate({ scrollTop: 0 }, 'slow');
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				// alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function() {

	}
}

/* Agree to Terms */
$(document).delegate('.agree', 'click', function(e) {
	e.preventDefault();

	$('#modal-agree').remove();

	var element = this;

	$.ajax({
		url: $(element).attr('href'),
		type: 'get',
		dataType: 'html',
		success: function(data) {
			html  = '<div id="modal-agree" class="modal">';
			html += '  <div class="modal-dialog">';
			html += '    <div class="modal-content">';
			html += '      <div class="modal-header">';
			html += '        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>';
			html += '        <h4 class="modal-title">' + $(element).text() + '</h4>';
			html += '      </div>';
			html += '      <div class="modal-body">' + data + '</div>';
			html += '    </div>';
			html += '  </div>';
			html += '</div>';

			$('body').append(html);

			$('#modal-agree').modal('show');
		}
	});
});

// Autocomplete */
(function($) {
	$.fn.autocomplete = function(option) {
		return this.each(function() {
			this.timer = null;
			this.items = new Array();

			$.extend(this, option);

			$(this).attr('autocomplete', 'off');

			// Focus
			$(this).on('focus', function() {
				this.request();
			});

			// Blur
			$(this).on('blur', function() {
				setTimeout(function(object) {
					object.hide();
				}, 200, this);
			});

			// Keydown
			$(this).on('keydown', function(event) {
				switch(event.keyCode) {
					case 27: // escape
						this.hide();
						break;
					default:
						this.request();
						break;
				}
			});

			// Click
			this.click = function(event) {
				event.preventDefault();

				value = $(event.target).parent().attr('data-value');

				if (value && this.items[value]) {
					this.select(this.items[value]);
				}
			}

			// Show
			this.show = function() {
				var pos = $(this).position();

				$(this).siblings('ul.dropdown-menu').css({
					top: pos.top + $(this).outerHeight(),
					left: pos.left
				});

				$(this).siblings('ul.dropdown-menu').show();
			}

			// Hide
			this.hide = function() {
				$(this).siblings('ul.dropdown-menu').hide();
			}

			// Request
			this.request = function() {
				clearTimeout(this.timer);

				this.timer = setTimeout(function(object) {
					object.source($(object).val(), $.proxy(object.response, object));
				}, 200, this);
			}

			// Response
			this.response = function(json) {
				html = '';

				if (json.length) {
					for (i = 0; i < json.length; i++) {
						this.items[json[i]['value']] = json[i];
					}

					for (i = 0; i < json.length; i++) {
						if (!json[i]['category']) {
							html += '<li data-value="' + json[i]['value'] + '"><a href="#">' + json[i]['label'] + '</a></li>';
						}
					}

					// Get all the ones with a categories
					var category = new Array();

					for (i = 0; i < json.length; i++) {
						if (json[i]['category']) {
							if (!category[json[i]['category']]) {
								category[json[i]['category']] = new Array();
								category[json[i]['category']]['name'] = json[i]['category'];
								category[json[i]['category']]['item'] = new Array();
							}

							category[json[i]['category']]['item'].push(json[i]);
						}
					}

					for (i in category) {
						html += '<li class="dropdown-header">' + category[i]['name'] + '</li>';

						for (j = 0; j < category[i]['item'].length; j++) {
							html += '<li data-value="' + category[i]['item'][j]['value'] + '"><a href="#">&nbsp;&nbsp;&nbsp;' + category[i]['item'][j]['label'] + '</a></li>';
						}
					}
				}

				if (html) {
					this.show();
				} else {
					this.hide();
				}

				$(this).siblings('ul.dropdown-menu').html(html);
			}

			$(this).after('<ul class="dropdown-menu"></ul>');
			$(this).siblings('ul.dropdown-menu').delegate('a', 'click', $.proxy(this.click, this));

		});
	}
})(window.jQuery);
//
// $(function(){
// 	$('form.filters').submit(function(){
// 		if($(this).find('input.attribute_id:checked').length > 0){
// 			if($('input[name=attribute_id]').length <= 0){
// 				var input = $('<input name="attribute_id" type="hidden">');
// 				$(this).append(input);
// 			} else {
// 				var input = $('input[name=attribute_id]');
// 			}
//
// 			$(this).find('input.attribute_id:checked').each(function(){
// 				input.val(input.val()+encodeURI($(this).val())+'_');
// 			});
// 		}
//
// 		if($(this).find('input.attribute:checked').length > 0){
// 			$(this).find('input.attribute:checked').each(function(){
// 				if($('input[name='+$(this).attr('attribute_id')+']').length > 0){
// 					var input = $('input[name='+$(this).attr('attribute_id')+']');
// 				} else {
// 					var input = $('<input name="'+$(this).attr('attribute_id')+'" type="hidden">');
// 					$(this).parent().append(input);
// 				}
//
// 				input.val(input.val()+encodeURI($(this).val())+'_');
//
// 			});
// 		}
//
// 		$('input[type=hidden][name *= "attribute_"]').each(function(){
// 			$(this).val($(this).val().substr(0,$(this).val().length - 1));
//
// 		});
// 	});
//
// 	$(".prices__filter .prices").change(function() {
// 		$(this).parent().find('.slider').slider("values", $(this).index()-1,$(this).val());
// 	});
// 	$('.filters > h5').click(function(){
// 		if($('.products').hasClass('open-filters')){
// 			$('.products').removeClass('open-filters');
// 			$('.product-list').removeClass('x3');
// 			$('.product-list').addClass('x4');
// 		} else {
// 			$('.products').addClass('open-filters');
// 			$('.product-list').removeClass('x4');
// 			$('.product-list').addClass('x3');
// 		}
// 	});
// 	$('body').on('click','.filters .filter-name',function(){
// 		$(this).parent().toggleClass('open');
// 	});
// 	$('.price-sorting .sort-items').click(function(){
// 		$(this).attr('href').split("&").filter(function(e){
// 			if(e.indexOf('order') !== -1){
// 				$(e.split('=')).each(function(index){
// 					if(this !== 'order'){
// 						$('form.filters input[name=order]').val(this);
// 					}
// 				});
// 			}
// 		});
// 		$('form.filters').submit();
// 		return false;
// 	});
// 	$('.count-sorting .sort-items').click(function(){
// 		$(this).attr('href').split("&").filter(function(e){
// 			if(e.indexOf('limit') !== -1){
// 				$(e.split('=')).each(function(index){
// 					if(this !== 'limit'){
// 						$('form.filters input[name=limit]').val(this);
// 					}
// 				});
// 			}
// 		});
// 		$('form.filters').submit();
// 		return false;
// 	});
// });